<template>
  <KPaginatedAutocomplete v-bind="$attrs" v-on="$listeners" :paginator="autocomplete"/>
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import { autocomplete } from '@/modules/store/api';

export default {
  name: 'RegionsAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    autocomplete(...args) {
      return autocomplete(...args);
    }
  },
};</script>
