import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (clientId, ...args) => getPaginated(`client/${clientId}/region`, ...args);

export const create = (clientId, region) => post(`client/${clientId}/region`, region);

export const show = (clientId, regionId) => get(`client/${clientId}/region/${regionId}`);

export const update = (clientId, region) => put(`client/${clientId}/region/${region.id}`, region);
